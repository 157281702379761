.btn {
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  min-width: 200px;
  border-radius: 25px;
  border-width: 2px;
  white-space: nowrap;
}

.btn-primary {
  color: #fff;
  background-color: #5dd39e;
  border-color: #5dd39e;
}

.Home .achievements .row h6 {
  font-family: "Oswald", "Times New Roman", Times, serif;
  color: #333;
  font-weight: 400;
  font-size: 18px;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  color: white;
}

.icon-box {
  color: #5dd39e;
  font-size: 3rem;
}

.counters span {
  font-size: 3rem;
  /* display: block; */
  color: var(--color-primary);
  font-weight: 600;
}

.counters {
  margin-top: 1rem;
}
.counters p {
  padding: 0;
  margin: 0 0 20px 0;
  /* font-family: "Raleway", sans-serif; */
  font-size: 14px;
  font-weight: 500;
  color: white;
}

p {
  color: #333;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 1.12rem;
}
.btn-primary:hover {
  color: #fff;
  background-color: #36c786;
  border-color: #34bf80;
}
.btn-primary:active {
  color: #fff;
  background-color: #36c786;
  border-color: #34bf80;
}
.btn-primary:focus {
  color: #fff;
  background-color: #36c786;
  border-color: #34bf80;
  box-shadow: unset;
}

strong {
  font-weight: bold;
}

.subheading {
  font-family: "Oswald", "Times New Roman", Times, serif;
}

@media (max-width: 500px) {
  .Home iframe {
    width: 100%;
    height: 250px;
  }
}
