.svg-box {
  color: #5dd39e;
  font-size: 3rem;
  padding-bottom: 10px;
}

.RnD h6 {
  font-family: "Oswald", "Times New Roman", Times, serif;
  color: #333;
  font-weight: 400;
  font-size: 18px;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

.RnD ul {
  padding-inline-start: 10px;
}
.RnD li {
  font-size: 17px;
}
.Training ul {
  padding-inline-start: 10px;
}

@media only screen and (max-width: 768px) {
  .RnD ul {
    padding-inline-start: 0px;
  }

  .Training ul {
    padding-inline-start: 0px;
  }
}
