.navigation .navbar{
    background-color: #222;
}
/* .navigation .nav-item { 
    color:#FFF;
} */
.navbar-collapse.collapse.show li{
    text-align: left;
    padding: 5px;
}
.navbar-collapse.collapsing li{
    padding: 5px;
    text-align: left;
}
@media (min-width: 991px) {
    
    .navigation .navbar {
        position: relative;
        padding-left: 10%;
        padding-right: 10%;
    } 
    
}
@media (max-width: 991px) {
    
    .navigation .header-uper {
        display: none;
    } 
    
}
.navbar{
    padding: 20px;
}
.nav-item{
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
    padding-left: 23px;
}
@media (min-width:992px){
.nav-link{
    padding: unset;
    
    border-left: 1px solid #333333;
}
}
.navigation .navbar-brand{
    float: left;
    padding-bottom: 5px;
    padding-top: unset;
}
.pagewide{
    background-color: white;
}
.header-uper .right-side {
    float: right;
}
.header-uper{
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
}
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: inline-block;
}
.header-uper  .social-links {
    position: relative;
    display: inline-block;
    margin-bottom: 0px;
}
.header-uper .social-links li i{
    width: 50px;
    height: 56px;
    line-height: 56px;
    margin: -1px;
    font-size: 16px;
    color: #777777;
    font-weight: 600;
    text-align: center;
    border: 1px solid #ececec;
}
.header-uper .social-links li i:hover{
    color:var(--primary-color);
    border: 1px solid var(--primary-color);
    cursor: pointer;
}
.social-links .phone-number i{
    color: #777777;
    padding-top:5px;
    margin-right: 5px;
}
.social-links .phone-number{
    border: 1px solid #ececec;
    padding:1rem 1rem 0rem 1rem;
    border-radius: 25px;
}
.social-links .phone-number p{
    font-weight: 400;
    font-size: 18px;
}
.social-links .phone-number:hover{
    color:var(--primary-color);
    border: 1px solid var(--primary-color);
}