.Quality .ul-list {
  padding-top: 80px;
}

.Quality ul {
  padding-inline-start: 10px;
}

@media only screen and (max-width: 768px) {
  .Quality .ul-list {
    padding-top: 0px;
  }

  .Quality ul {
    padding-inline-start: 0px;
  }
}
