.footer {
  background-color: #222;
  color: white;
}
.footer .contact {
  background-color: var(--primary-color);
}
.footer .contactus li i {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.2rem;
  padding-top: 1px;
  color: black;
}
.footer .contactus {
  margin-top: 20px;
}
.footer .contactus li a {
  color: #fff;
}
.footer .contactus li {
  position: relative;
  float: left;
  padding-left: 30px;
  margin-bottom: 15px;
  /* display: flex; */
}
.footer .contactus li p {
  color: black;
  position: relative;
  text-align: start;
  font-size: 1rem;
}
.footer .social-icons li {
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin: -1px;
  font-size: 16px;
  color: #777777;
  font-weight: 600;
  text-align: center;
  border: 1px solid #ececec;
  background-color: white;
  border-radius: 100%;
  margin: 5px;
}
.footer .social-icons li:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.footer .social-icons {
  padding-left: 33%;
  /* margin-right: auto; */
}
.footer .sub-heading {
  font-family: "Oswald";
}
.footer .latest-news {
  text-align: start;
}
.footer .latest-news li {
  margin: 5px;
  font-size: 1rem;
  color: #aaa;
}
