h2 {
  font-family: "Oswald", "Times New Roman", Times, serif;
}

.Aboutus h6 {
  font-family: "Oswald", "Times New Roman", Times, serif;
  border-left-style: solid;
  border-left-color: #5dd39e;
  border-left-width: 3px;
  padding: 5px;
  font-size: 1.25rem;
}

.Aboutus li {
  padding-bottom: 5px;
  line-height: 1.9;
  font-size: 1.05rem;
}

.Aboutus ul {
  padding-inline-start: 10px;
}

/*.Aboutus .far .fa-dot-circle {
  color: #5dd39e;
}*/

@media only screen and (max-width: 768px) {
  .Aboutus ul {
    padding-inline-start: 0px;
  }
}
